<template>
    <div class="ball">
        <!-- :value="true"  -->
        <div v-for="(item, index) in ballList" :key="index" @click="selectedBall(item.id)" style="width:68px;height:68px;">
            <el-tooltip popper-class='tps' :content="item.name" placement="left" effect="light">
                <img :src="$store.state.ballId == item.id ? item.xzImg : item.img" alt="">
            </el-tooltip>
        </div>
    </div>
</template>

<script>
export default {
    props: {

    },
    data() {
        return {
            ballList: [
                {
                    id: 1,
                    name: this.$t('message.Default'),
                    xzImg: require("@/assets/map/ball2.png"),
                    img: require("@/assets/map/ball1.png")
                },
                {
                    id: 2,
                    name: this.$t('message.NortheasternHemisphere'),
                    xzImg: require("@/assets/map/ball2_db.png"),
                    img: require("@/assets/map/ball1_db.png")
                },
                {
                    id: 3,
                    name: this.$t('message.northwesternhemisphere'),
                    xzImg: require("@/assets/map/ball2_xb.png"),
                    img: require("@/assets/map/ball1_xb.png")
                },
                {
                    id: 4,
                    name: this.$t('message.SoutheasternHemisphere'),
                    xzImg: require("@/assets/map/ball2_dn.png"),
                    img: require("@/assets/map/ball1_dn.png")
                },
                {
                    id: 5,
                    name: this.$t('message.southwesternhemisphere'),
                    xzImg: require("@/assets/map/ball2_xn.png"),
                    img: require("@/assets/map/ball1_xn.png")
                }
            ]
        };
    },
    created() {

    },
    beforeCreate: function () { },
    methods: {
        selectedBall(id) {
            if(this.$store.state.ballId == id){

                return
            }
            sessionStorage.setItem('ballId', id);
            this.$store.state.ballId = id;
            this.$emit("selectedBall", id);
        }
    },
    mounted() {

    },
    watch: {

    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.ball {
    position: fixed;
    right: 25px;
    bottom: 110px;
}

// .tps .el-tooltip__popper.is-light {
//     border: 1px solid var(--main-color);
// }

// /deep/ .tps .el-tooltip__popper.is-light[x-placement^='right'] .popper__arrow:after {
//     border-right-color: var(--main-color) !important;
// }

// /deep/.popper__arrow {
//     background-color: var(--main-color) !important;
// }
</style>