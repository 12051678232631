import request from '@/api/request'

// 上传图标
export async function getUploadIcon(data) {
    return await request({
        url: '/icon/uploadIcon',
        method: 'post',
        data: data
    })
}

// 设置终端图标
export async function setUserIcon(data) {
    return await request({
        url: '/icon/setUserIcon',
        method: 'post',
        data: data
    })
}

// 图标删除
export async function deleteIcon(data) {
    return await request({
        url: '/icon/deleteIcon',
        method: 'post',
        data: data
    })
}

// 图标查询
export async function getQueryIcon(data) {
    return await request({
        url: '/icon/queryIcon',
        method: 'post',
        data: data
    })
}

// 查询终端图标
export async function getQueryUserIcon(data) {
    return await request({
        url: '/icon/queryUserIcon',
        method: 'post',
        data: data
    })
}

// 查询标记
export async function getQueryMark(data) {
    return await request({
        url: '/icon/queryMark',
        method: 'post',
        data: data
    })
}


// 添加标记
export async function addMark(data) {
    return await request({
        url: '/icon/addMark',
        method: 'post',
        data: data
    })
}

// 修改标记
export async function updateMark(data) {
    return await request({
        url: '/icon/updateMark',
        method: 'post',
        data: data
    })
}

// 删除标记
export async function deleteMark(data) {
    return await request({
        url: '/icon/deleteMark',
        method: 'post',
        data: data
    })
}
